/* critical:start */
html, body.v2 {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: @dark-grey;
    min-width: 320px;
    font-size: @base-font-size;
    font-family: @font-family;

    #app {
        width: 100%;
        height: 100%;
        display: grid;
        grid-row-gap: 0;
        grid-template-rows: 50px auto 55px;
    }
}

*, *:before, *:after {
    box-sizing: inherit;
}

.mainView {
    padding: 1.5em;
    background-color: @background;
    overflow: hidden;
}

.td-input, .td-form input, .td-select, textarea {
    padding: 0.5em;
    border: 0.09em solid @border-color;
    border-radius: @border-radius;
    width: 100%;
    font-size: inherit;
}

.show-mobile {
    display: none !important;
}

.td-full-width {
    width: 100%;
}

.td-mx-auto {
    margin: 0 auto;
}

.td-page {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.td-container {
    max-width: 1200px;
    margin: 0 1.5em;
    padding: 1.5em;

    &.fluid {
        max-width: 100%;
        margin: 0;
    }

    &.fullheight {
        height: 100%;
    }

    @media only screen and (min-width: 1240px) {
        margin: 0 auto;
    }
}

.td-text-mice {
    font-size: 0.8em;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.td-card {
    padding: 1em;
}

/* Grid Layout Helpers */

@column-values: 1, 2, 3, 4;

.grid-class(@col_val, @gap, @gap_row) {
    display: grid;
    grid-template-columns: repeat(@col_val, 1fr);
    grid-column-gap: @gap;
    grid-row-gap: @gap_row;
}

.generate-grid-classes(@n, @values, @i: 1) when (@i =< @n) {
    @value: extract(@values, @i);

    .col-@{value} {
        .grid-class(@value, 1em, 0.75em);
    }

    @media @x-sm-up {
        .x-sm-col-@{value} {
            .grid-class(@value, 0.5em, 0.25em);
        }
    }

    @media @sm-up {
        .sm-col-@{value} {
            .grid-class(@value, 1em, 0.5em);
        }
    }

    @media @md-up {
        .md-col-@{value} {
            .grid-class(@value, 1.5em, 0.75em);
        }
    }

    @media @lg-up {
        .lg-col-@{value} {
            .grid-class(@value, 1.5em, 1em);
        }
    }

    @media @xl-up {
        .xl-col-@{value} {
            .grid-class(@value, 1.5em, 1em);
        }
    }

    .generate-grid-classes(@n, @values, (@i + 1));
}

.generate-grid-classes(length(@column-values), @column-values);

/* Grid Column Layout Helpers End*/

.ma-0 {
    margin: 0;
}

.pa-1 {
    padding: 0.5em;
}

button, input[type="submit"], .td-btn {
    padding: 0.5em 1.25em;
    border: 0.09em solid #caced3;
    font-size: 1em;
}

.td-autocomplete .autocomplete-input {
    width: 100%;
    border: 0.09em solid #caced3;
    padding: 0.5em;
    display: inline-block;
    overflow: hidden;
    position: relative;
    z-index: 1;
    background-color: #fff;

    > input {
        width: 100%;
        border: 0;
    }
}

.td-mx-auto {
    margin: 0 auto;
}

.td-divider {
    border: none;
    height: 0.07em;
    background-color: @divider;
    width: 100%;
}

/* Text */
.td-text-center {
    text-align: center;
}

.td-text-right {
    text-align: right;
}

.td-text-left {
    text-align: left;
}

.td-text-uppercase {
    text-transform: uppercase;
}

h1, .td-h1 {
    font-size: 2em;
    margin: 1em 0;
}

h2, .td-h2 {
    font-size: 1.75em;
    margin: 1em 0;
}

h3, .td-h3 {
    font-size: 1.5em;
    margin: 0.75em 0;
}

h4, .td-h4 {
    font-size: 1.25em;
    margin: 0.75em 0;
}

h5, .td-h5 {
    font-size: 1em;
    margin: 0.5em 0;
}

h6, .td-h6 {
    font-size: 0.85em;
    margin: 0.5em 0 0.25em 0;
}

/* Text end */

/* icons */

svg:not(:root) {
    overflow-clip-margin: content-box;
    overflow: hidden;
}

.svg-inline--fa {
    display: var(--fa-display, inline-block);
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
}

svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa {
    overflow: visible;
    box-sizing: content-box;
}

/* Colors */

.td-text-primary {
    color: @planrv-primary;
}

.td-text-subdued {
    color: @text-subdued;
    font-weight: 300;
}

h1, h2, h3, h4, h5, h6 {
    color: @dark-grey;
}

.td-text-primary-dark {
    color: @planrv-primary-active;
}

.td-text-secondary {
    color: @planrv-secondary-dark;
}

.td-text-danger {
    color: @error;
}

.td-text-success {
    color: @green;
}

/* Colors End */

/* Links */

a, .td-link {
    color: @planrv-link;
    cursor: pointer;

    &:hover {
        color: @planrv-primary-active;
        text-decoration: underline;
    }

    &.no-underline {
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }
}
/* Links End */

/* Modal */
.modal-footer {
    .actions {
        display: flex;
        justify-content: space-between;
    }
}

@media @md-down {
    html, body.v2 {
        font-size: 16px;
    }

    .hide-mobile {
        display: none !important;
    }

    .show-mobile {
        display: initial !important;
    }

    .td-container {
        margin: 0 1em;
        padding: 1em;
    }
}

@media @sm-down {

    .td-container {
        margin: 0 0.5em;
        padding: 0.75em;
    }

    h1, .td-h1 {
        font-size: 1.75em;
    }

    h2, .td-h2 {
        font-size: 1.5em;
    }

    h3, .td-h3 {
        font-size: 1.25em;
    }

    h4, .td-h4 {
        font-size: 1.15em;
    }

    h5, .td-h5 {
        font-size: 1em;
    }

    h6, .td-h6 {
        font-size: 0.85em;
    }

    .td-list {
        grid-template-columns: 1fr;

        li {
            padding: 0.4em 0;
        }
    }
}

@media @x-sm-only {
    .td-card {
        padding: 0.75em;
    }

    .td-container {
        max-width: 100%;
        margin: 0;
    }
}

/* critical:end */
.td-modal {
    padding: 2.5em;
    background-color: @background;
    border-radius: @border-radius;
    top: 50px;
    position: relative;
    overflow-y: auto;
    min-height: calc(95vh - 60px);
    max-height: calc(100vh - 90px);
    scroll-behavior: smooth;
}

.td-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.td-close-modal {
    position: absolute;
    top: 60px;
    right: 40px;

    svg {
        font-size: 1.5em;
        color: @dark-grey;
        z-index: 10;
        position: absolute;
        cursor: pointer;

        &:hover {
            color: @planrv-primary-active;
        }
    }
}

/* Transition name="modal" */

.modal-enter-active,
.modal-leave-active {
    transition: opacity 0.5s ease;
}

.modal-enter,
.modal-leave-to {
    opacity: 0;
}

/* Modal End */

/* Cards  */

.td-card {
    box-shadow: @base-shadow;
    padding: 1em;
    background-color: white;

    &.grey {
        background-color: @background;
    }

    &.shadow {
        box-shadow: @card-shadow;
    }

    &.rounded {
        border-radius: 6px;
    }
}

/* Card End */

/* Breadcrumbs */

.breadcrumbs {
    font-size: 0.85em;

    ul {
        list-style: none;
        padding: 0;
    }

    li {
        display: inline-block;
    }

    li:nth-child(n+2):before {
        content: '/';
        display: inline-block;
        margin: 0 0.5em;
        font-weight: bold;
    }

    a {
        text-decoration: none;
    }
}

/* Breadcrumbs End */

.td-full-width {
    width: 100%;
    max-width: 100%;
}

/* Spacing helpers */

@padding-values: 0, 1, 2, 3, 4, 5;

// mixin for different breakpoints
.generate-classes(@pr, @size, @val) {
    .@{pr}a-@{size}-@{val} {
        & when (@pr = p) {
            padding: unit(@val * 0.5, em);
        }

        & when (@pr = m) {
            margin: unit(@val * 0.5, em);
        }
    }

    .@{pr}y-@{size}-@{val} {
        & when (@pr = p) {
            padding-top: unit(@val * 0.5, em);
            padding-bottom: unit(@val * 0.5, em);
        }

        & when (@pr = m) {
            margin-top: unit(@val * 0.5, em);
            margin-bottom: unit(@val * 0.5, em);
        }
    }

    .@{pr}x-@{size}-@{val} {
        & when (@pr = p) {
            padding-left: unit(@val * 0.5, em);
            padding-right: unit(@val * 0.5, em);
        }

        & when (@pr = m) {
            margin-left: unit(@val * 0.5, em);
            margin-right: unit(@val * 0.5, em);
        }
    }

    .@{pr}t-@{size}-@{val} {
        & when (@pr = p) {
            padding-top: unit(@val * 0.5, em);
        }

        & when (@pr = m) {
            margin-top: unit(@val * 0.5, em);
        }
    }

    .@{pr}b-@{size}-@{val} {
        & when (@pr = p) {
            padding-bottom: unit(@val * 0.5, em);
        }

        & when (@pr = m) {
            margin-bottom: unit(@val * 0.5, em);
        }
    }

    .@{pr}r-@{size}-@{val} {
        & when (@pr = p) {
            padding-right: unit(@val * 0.5, em);
        }

        & when (@pr = m) {
            margin-right: unit(@val * 0.5, em);
        }
    }

    .@{pr}l-@{size}-@{val} {
        & when (@pr = p) {
            padding-left: unit(@val * 0.5, em);
        }

        & when (@pr = m) {
            margin-left: unit(@val * 0.5, em);
        }
    }
}

body.v2 {
    .generate-spacing-classes(@prefix, @n, @values, @i: 1) when (@i =< @n) {
        @value: extract(@values, @i);

        .@{prefix}a-@{value} {
            & when (@prefix = p) {
                padding: unit(@value * 0.5, em);
            }

            & when (@prefix = m) {
                margin: unit(@value * 0.5, em);
            }
        }

        .@{prefix}y-@{value} {
            & when (@prefix = p) {
                padding-top: unit(@value * 0.5, em);
                padding-bottom: unit(@value * 0.5, em);
            }

            & when (@prefix = m) {
                margin-top: unit(@value * 0.5, em);
                margin-bottom: unit(@value * 0.5, em);
            }
        }

        .@{prefix}x-@{value} {
            & when (@prefix = p) {
                padding-left: unit(@value * 0.5, em);
                padding-right: unit(@value * 0.5, em);
            }

            & when (@prefix = m) {
                margin-left: unit(@value * 0.5, em);
                margin-right: unit(@value * 0.5, em);
            }
        }

        .@{prefix}t-@{value} {
            & when (@prefix = p) {
                padding-top: unit(@value * 0.5, em);
            }

            & when (@prefix = m) {
                margin-top: unit(@value * 0.5, em);
            }
        }

        .@{prefix}b-@{value} {
            & when (@prefix = p) {
                padding-bottom: unit(@value * 0.5, em);
            }

            & when (@prefix = m) {
                margin-bottom: unit(@value * 0.5, em);
            }
        }

        .@{prefix}r-@{value} {
            & when (@prefix = p) {
                padding-right: unit(@value * 0.5, em);
            }

            & when (@prefix = m) {
                margin-right: unit(@value * 0.5, em);
            }
        }

        .@{prefix}l-@{value} {
            & when (@prefix = p) {
                padding-left: unit(@value * 0.5, em);
            }

            & when (@prefix = m) {
                margin-left: unit(@value * 0.5, em);
            }
        }

        @media @sm-up {
            .generate-classes(@prefix, sm, @value);
        }

        @media @md-up {
            .generate-classes(@prefix, md, @value);
        }

        @media @lg-up {
            .generate-classes(@prefix, lg, @value);
        }

        @media @xl-up {
            .generate-classes(@prefix, xl, @value);
        }

        .generate-spacing-classes(@prefix, @n, @values, (@i + 1));
    }

    .generate-spacing-classes(p, length(@padding-values), @padding-values);
    .generate-spacing-classes(m, length(@padding-values), @padding-values);
}
/* Spacing Helpers End */


/* Banners */

.td-banner {
    padding: 1em;
    border-left: 3px solid @gray;
    background-color: @background;
    margin-top: 0.25em;
    margin-bottom: 0.75em;
    box-shadow: 0 0 5px rgba(23,24,24,.05), 0 1px 2px rgba(0,0,0,.15);
    display: grid;
    grid-template-columns: 1em calc(100% - 1em);
    justify-content: start;
    border-radius: 4px;
    align-items: center;
    column-gap: 0.75em;
    color: @dark-grey;
    position: relative;

    h1, h2, h3, h4, h5, h6 {
        margin: 0 0 0.25em 0;
        color: inherit;
    }

    p {
        margin: 0;
        line-height: 1.15em;
        grid-column-start: 2;
    }

    &.dismissible {
        svg.fa-xmark {
            position: absolute;
            top: 8px;
            right: 8px;
            font-size: 0.9em;
            color: inherit;

            &:hover {
                color: @dark-grey;
                cursor: pointer;
            }
        }

        p {
            margin: 0 1em 0 0;
        }

        h1, h2, h3, h4, h5, h6 {
            margin: 0 1em 0.25em 0;
        }
    }

    &.no-icon {
        display: block;
    }

    &.border {
        border: 0.08em solid @border-color;
        box-shadow: none;
    }

    &.slim {
        padding: 0.5em;
        font-size: .8em;
        column-gap: 0.4em;
        grid-template-columns: 16px calc(100% - 16px);

        &.dismissible {
            svg.fa-xmark {
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    &.danger {
        color: @error-dark;
        background-color: @background-error;
        border-left: 3px solid @error-dark;

        &.border {
            border: 0.08em solid @error-dark;
        }
    }

    &.warning {
        color: @planrv-primary;
        background-color: @planrv-light;
        border-left: 3px solid @planrv-primary;

        &.border {
            border: 0.08em solid @planrv-primary;
        }
    }

    &.info {
        color: @planrv-secondary-dark;
        background-color: @planrv-secondary-background;
        border-left: 3px solid @planrv-secondary-dark;

        &.border {
            border: 0.08em solid @planrv-secondary-dark;
        }
    }

    &.success {
        color: @green;
        background-color: @background-green;
        border-left: 3px solid @green;

        &.border {
            border: 0.08em solid @green;
        }
    }
}

/* Banners End */

/* List */

.td-list {
    list-style-type: none;
    display: grid;
    justify-items: baseline;
    padding: 0;
    margin: 0.5em 0;
    grid-row-gap: 0;

    svg {
        display: inline-block;
        margin-right: 0.25em;
    }

    p {
        display: inline;
    }

    &.styled {
        list-style-type: disc;
        margin: 0.5em 1em;
    }

    li {
        padding: 0.25em 0;
        font-weight: normal !important;
    }
}

/* List End */

/* Buttons */

.btn-styles() {
    padding: 0.5em 1.25em;
    border-radius: @border-radius;
    cursor: pointer;
    font-size: 1em;
    border: none;
    background-color: #fff;
    color: @dark-grey;
    border: 0.09em solid @border-color;
    box-shadow: @button-shadow;
}

button, input[type="submit"], .td-btn {
    .btn-styles();

    &:hover {
        background-color: @background;
    }
}

a.td-btn, a.td-btn:hover {
    text-decoration: none;
    display: inline-block;
}

button.td-link {
    border: none;
    box-shadow: none;
    padding: 0;
    background-color: inherit;
}

// button colors
.td-btn.primary, input[type="submit"] {
    border: none;
    background-color: @planrv-primary;
    color: #fff;

    &:hover, &:active {
        background-color: @planrv-primary-active;
    }
}

.td-btn.primary-outlined {
    border: none;
    background-color: #fff;
    color: @planrv-primary;
    box-shadow: inset 0 0 0 0.09em @planrv-primary;
    box-sizing: border-box;

    &:hover, &:active {
        box-shadow: inset 0 0 0 0.09em @planrv-primary-active;
        color: @planrv-primary-active;
        background-color: @planrv-light;
    }
}

.td-btn.secondary {
    background-color: @planrv-secondary-dark;
    border: none;
    color: #fff;

    &:hover, &:active {
        background-color: @planrv-secondary-active;
    }
}

.td-btn.secondary-outlined {
    border: none;
    background-color: #fff;
    color: @planrv-secondary-dark;
    box-sizing: border-box;
    box-shadow: inset 0 0 0 0.09em @planrv-secondary-dark;

    &:hover, &:active {
        box-shadow: inset 0 0 0 0.09em @planrv-secondary-active;
        color: @planrv-secondary-active;
        background-color: @planrv-secondary-background;
    }
}

.td-btn.danger {
    background-color: @error;
    color: #fff;
    border: none;

    &:hover, &:active {
        background-color: @error-dark;
    }
}

button.disabled, a.disabled {
    opacity: 0.7;
    cursor: initial;
}

//button shapes
button.slim, button.td-btn.icon-only.slim, a.slim {
    padding: 0.45em 0.75em;
    font-size: 0.9em;
}

.td-btn.icon-left, .td-btn.icon-right, a.icon-left, a.icon-right {
    display: inline-grid;
    grid-column-gap: 0.5em;
    align-items: center;
    justify-items: center;
    justify-content: center;
}

.td-btn.icon-left, a.icon-left {
    grid-template-columns: auto max-content;
}

.td-btn.icon-right, a.icon-right {
    grid-template-columns: max-content auto;
}

.td-btn.icon-only, a.td-btn.icon-only {
    padding: 0.5em;
    border: 0.085em solid #abb1ba;
    box-shadow: 0em 0.0625em 0.0625em rgba(31,33,36,.1);
    width: 2.2em;
    height: 2.2em;
    background-color: inherit;

    svg {
        color: @dark-grey;
    }

    &:hover {
        background-color: @background;
    }

    &.primary-outlined {
        border-color: @planrv-primary;

        &:hover {
            box-shadow: 0em 0.0625em 0.0625em rgba(31,33,36,.1);
        }

        svg {
            color: @planrv-primary;
        }
    }

    &.primary {
        border-color: @planrv-primary;
        background-color: @planrv-primary;

        svg {
            color: #fff;
        }

        &:hover {
            background-color: @planrv-primary-active;
        }
    }

    &.danger {
        border-color: @error;
        background-color: @error;

        svg {
            color: #fff;
        }

        &:hover {
            background-color: @error-dark;
            border-color: @error-dark;
        }
    }

    &.secondary-outlined {
        border-color: @planrv-secondary-dark;

        &:hover {
            box-shadow: 0em 0.0625em 0.0625em rgba(31,33,36,.1);
        }

        svg {
            color: @planrv-secondary-dark;
        }
    }

    &.secondary {
        border-color: @planrv-secondary-dark;
        background-color: @planrv-secondary-dark;

        svg {
            color: #fff;
        }

        &:hover {
            background-color: @planrv-secondary-active;
        }
    }
}

/* Pagination buttons */

.pagination-btn-style(@bg-color, @bg-font-color, @font-color, @border-color, @hover-bg, @hover-border) {
    border: 1px solid @border-color;
    padding: 0.5em 1em;
    opacity: 0.95;

    &.next {
        background-color: @bg-color;
        color: @bg-font-color;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border: 1px solid @border-color;

        &:hover {
            background-color: @hover-bg;
            border: 1px solid @hover-border;
        }

        &.disabled {
            opacity: 0.5;
            cursor: initial;

            &:hover, &:active, &:focus {
                opacity: 0.5;
                background-color: @bg-color;
            }
        }
    }

    &.previous {
        background-color: inherit;
        color: @font-color;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        &:hover {
            background-color: @background;
        }

        &.disabled {
            opacity: 0.5;
            cursor: initial;

            &:hover, &:active, &:focus {
                opacity: 0.5;
                background-color: inherit;
            }
        }
    }

    &:hover {
        opacity: 1;
    }
}

.td-pagination-btns {
    justify-self: start;

    a {
        padding: 0 0.5em;
        color: @dark-grey;
        text-decoration: none;


        &.active {
            color: @planrv-primary-active;
        }
    }

    button {
        .pagination-btn-style(transparent, @dark-grey, @dark-grey, @border-color, @background, @border-color);
    }

    &.secondary {
        a {
            color: @planrv-secondary-dark;
        }

        button {
            .pagination-btn-style(@planrv-secondary-dark, #fff, @planrv-secondary-dark, @planrv-secondary-dark, @planrv-secondary-active, @planrv-secondary-active);
        }
    }

    &.primary {
        a {
            color: @planrv-primary;
        }

        button {
            .pagination-btn-style(@planrv-primary, #fff, @planrv-primary, @planrv-primary, @planrv-primary-active, @planrv-primary-active);
        }
    }

    &.slim {
        button {
            padding: 0.25em 0.5em;

            svg {
                font-size: 0.9em;
            }
        }
    }
}

/* End Buttons */

/* Checkbox */

.td-checkbox-label {
    display: block;
    position: relative;
    padding-left: 1.8em;
    margin-bottom: 0.5em;
    cursor: pointer;
    padding-top: 0.5em;

    > input[type=checkbox] {
        display: none;
    }

    input + span {
        position: absolute;
        top: 0.5em;
        left: 0;
        height: 1.3em;
        width: 1.3em;
        border: none;
        box-shadow: inset 0 0 0 0.09em @planrv-primary;
        border-radius: @border-radius;
        transition: 0.25s ease;

        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 0.5em;
            bottom: 0.35em;
            width: 0.4em;
            height: 0.75em;
            border: solid white;
            border-width: 0 0.2rem 0.2rem 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            transition: 0.25s ease;
        }
    }

    & input {
        &:active ~ span {
            background-color: @planrv-light;
        }

        &:checked ~ span {
            background-color: @planrv-primary;

            &:after {
                display: block;
            }
        }
    }
}
/* Checkbox End */

/* Radio Button */

.td-radio {
    display: flex;
    margin-left: -0.4em;

    &.wrap {
        flex-wrap: wrap;
    }

    > label {
        display: flex;
        cursor: pointer;
        font-weight: 500;
        position: relative;
        overflow: hidden;
        margin-right: 0.5em;
        font-size: 1em;

        input {
            position: absolute;
            left: -9999px;

            &:checked + span {
                background-color: transparent;

                &:before {
                    box-shadow: inset 0 0 0 0.4375em @planrv-primary;
                }
            }
        }

        span {
            display: flex;
            align-items: center;
            padding: 0.375em 0.75em 0.375em 0.375em;
            border-radius: 99em;
            transition: 0.25s ease;

            &:before {
                display: flex;
                flex-shrink: 0;
                content: "";
                background-color: #fff;
                width: 1.5em;
                height: 1.5em;
                border-radius: 50%;
                margin-right: 0.5em;
                transition: 0.25s ease;
                box-shadow: inset 0 0 0 0.125em @planrv-primary;
                font-size: 0.9em;
            }
        }
    }

    &.background label {
        input:checked + span {
            background-color: mix(#fff, @planrv-primary, 84%);
        }

        span:hover {
            background-color: mix(#fff, @planrv-primary, 84%);
        }
    }
}
/* Radio button end */
/* Form input with autocomplete dropdown */

.td-selected-input {
    background-color: #eeeeee;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    width: fit-content;
    margin: 0 0.25rem;
    border-radius: 1.8em;
    padding: 0 0.25em;
    box-shadow: 0 0 5px rgba(23, 24, 24, 0.05), 0 1px 2px rgba(0, 0, 0, 0.15);
    font-size: 0.9em;

    > span {
        padding: 0.2em 0.25rem;

        > a.input-deselect {
            display: inline-block;
            border-left: 1px solid @divider;
            margin-left: 0.25em;
            padding: 0 0.25em;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.td-autocomplete {
    .autocomplete-input {
        width: 100%;
        border: 0.09em solid @border-color;
        border-radius: 0.188rem;
        box-sizing: border-box;
        padding: 0.5em;
        display: inline-block;
        overflow: hidden;
        position: relative;
        z-index: 1;
        background-color: #fff;

        &.error {
            border: 0.09em solid @error;
        }

        &.disabled {
            opacity: 0.7;
            cursor: not-allowed;
        }

        &.focused, &:focus {
            border-color: @planrv-secondary-dark;
        }

        > input {
            width: 100%;
            padding: 0;
            border-style: none;
            border-width: 0;
            border-color: none;
            outline-color: none;
            background-color: #fff;
            margin: 0;

            &.hidden-input {
                display: none;
            }

            &:focus {
                border-style: none;
                border-width: 0;
                border-color: none;
                outline-color: none;
                background-color: #fff;
            }
        }
    }

    > .autocomplete-results {
        // hide/show content
        max-height: 15.125rem;
        overflow-x: hidden;
        overflow-y: scroll;
        margin-bottom: 0.375rem;
        padding: 0.313rem;
        background-color: #f9f9f9;
        box-shadow: @base-shadow;
        margin-top: 0.25em;
        display: block;

        &.hide-results {
            display: none;
        }
        // content scroll bar style
        &::-webkit-scrollbar {
            width: 0.438rem;
            background-color: #F0F0F0;
        }

        &::-webkit-scrollbar-thumb {
            background-color: @planrv-secondary-dark;
            border-radius: 0.313rem;
        }
        // list of search options
        .results-list {
            list-style-type: none;
            padding-left: 0;
            margin: 0;

            > li {

                &:hover {
                    background-color: @dark-grey;
                    color: white;
                }

                > a, > span {
                    padding: 0.313rem 0.438rem;
                    display: inline-block;
                    width: 100%;
                    text-align: left;
                    line-height: 1.25em;
                    text-decoration: none;

                    @media @sm-down {
                        font-size: 1rem;
                    }

                    @media @x-sm-only {
                        font-size: 0.95rem;
                    }
                }

                span {
                    font-size: 0.92em;

                    &:hover {
                        background-color: #f9f9f9;
                        color: @dark-grey;
                    }
                }
            }
        }
    }
}
/* Form input with autocomplete dropdown end */

/* Badges */

.td-badge {
    text-align: center;
    font-weight: normal;
    padding: 0.25em 0.75em;
    border-radius: 1.8em;
    box-shadow: 0 0 5px rgba(23, 24, 24, 0.05), 0 1px 2px rgba(0, 0, 0, 0.15);
    font-size: 0.9em;
    vertical-align: middle;
    align-items: center;
    display: inline-flex;
    width: fit-content;
    height: fit-content;

    svg {
        font-size: 0.9em;
        padding-right: 0.4em;
    }

    &.primary {
        background-color: @planrv-light;
        color: @planrv-primary;
    }

    &.dark {
        background-color: #e7e7e7;
        color: @dark-grey;
    }

    &.secondary {
        background-color: @planrv-secondary-background;
        color: @planrv-secondary-dark;
    }

    &.danger {
        background-color: @background-error;
        color: @error-dark;
    }

    &.slim {
        font-size: 0.78em;
    }
}

/* Badges End */

/* Tooltips */

.label-with-tooltip {
    padding: 0.25em 0;

    label {
        display: inline;
        margin-right: 0.5em;
    }
}

.td-tooltip-container {
    position: relative;
    display: inline-block;

    .hover-mark {
        width: 26px;
        height: 26px;
        background-color: @planrv-secondary-dark;
        color: #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-weight: bold;
    }

    .hover-element {
        cursor: pointer;
    }

    .tooltip {
        position: absolute;
        background-color: @dark-grey;
        color: #fff;
        border-radius: @border-radius;
        padding: 0.3em;
        font-size: 0.9em;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.2s;
        bottom: 125%;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);

        @media @md-down {
            left: 150%;
        }
    }

    &:hover .tooltip, &.active .tooltip {
        visibility: visible;
        opacity: 1;
    }
}

/* Tooltips End */

/* Form Inputs */

//input styles
.input-styles() {
    outline-color: @planrv-secondary-dark;
    position: relative;
    width: 100%;
    min-width: 0;
    background-color: #fff;
    caret-color: @dark-grey;
    color: @dark-grey;
    padding: 0.5em;
    border: 0.09em solid @border-color;
    border-radius: @border-radius;
    font-family: inherit;
    font-size: inherit;
    margin: 0.15em 0;

    &:focus, &.focus {
        border: 0.09em solid @planrv-secondary-dark;
        background-color: @planrv-secondary-background;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px @planrv-secondary-background inset !important;
    }

    &.error {
        border: 0.09em solid @error-dark;
        background-color: @background-error;
    }

    &.disabled {
        opacity: 0.7;
    }
}

input, input.td-input, textarea, select {
    .input-styles();
}

/* datepicker */
.dp__main .dp__theme_light {
    --dp-text-color: @dark-grey;
    --dp-primary-color: @planrv-secondary-dark;
    --dp-border-color: @divider;
    --dp-success-color: @green;
    --dp-icon-color: @gray;
    --dp-danger-color: @error;
}

.td-form {
    /* datepicker */
    .dp__input {
        padding-top: 0.38em;
        padding-bottom: 0.38em;
        padding-left: 2em;
        border: 0.09em solid @border-color;
        outline-color: @planrv-secondary-dark;
        border-radius: @border-radius;
        color: @dark-grey;
    }

    label {
        display: block;
        margin-bottom: 0.25em;

        &.td-label-inline {
            display: inline;
        }
    }
}

.td-select {
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)no-repeat 99% 50%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
}

// input error styles
.td-error {
    margin-top: 0.25em;
    margin-bottom: 0.75em;
    color: @error-dark;
    font-size: 0.85em;
    line-height: 1.15em;
}

*:focus {
    outline: none !important;
}
/* Inputs End */

/* Tabs */

.td-tabs {
    background-color: inherit;
    display: flex;
    justify-content: left;
    column-gap: 0.5em;
    font-size: 0.8em;

    &.secondary {
        .td-tab, span {

            &.active {
                color: @planrv-secondary-dark;
                background-color: @planrv-secondary-background;

                &:hover {
                    color: @dark-grey;
                    background-color: @planrv-secondary-background;
                }
            }
        }
    }

    &.primary {
        .td-tab, span {

            &.active {
                color: @planrv-primary;
                background-color: @planrv-light;

                &:hover {
                    color: @dark-grey;
                    background-color: @planrv-light;
                }
            }
        }
    }

    .td-tab, span {
        font-weight: bold;
        cursor: pointer;
        padding: 0.5em 0.75em;
        border-radius: @border-radius;
        color: @text-subdued;

        &:hover {
            color: @dark-grey;
            background-color: #dfdfdf;
        }
    }
}
/* Tabs end */

.td-container {
    max-width: 1200px;
    margin: 0 1.5em;
    padding: 1.5em;

    &.fluid {
        max-width: 100%;
        margin: 0;
    }

    @media only screen and (min-width: 1240px) {
        margin: 0 auto;
    }
}

@media @md-down {

    .td-checkbox-label {
        span:after {
            left: 0.45em;
        }
    }

    .td-radio {
        > label {
            font-size: 0.9em;
        }
    }

    .td-container {
        margin: 0 1em;
        padding: 1em;
    }
}

@media @sm-down {
    button, button.td-btn {
        padding: 0.5em 0.75em;
        font-size: 0.9em;
    }

    .td-radio.sm-wrap {
        flex-wrap: wrap;
        row-gap: 0.25em;
    }

    h1, .td-h1 {
        font-size: 1.75em;
    }

    h2, .td-h2 {
        font-size: 1.5em;
    }

    h3, .td-h3 {
        font-size: 1.25em;
    }

    h4, .td-h4 {
        font-size: 1.15em;
    }

    h5, .td-h5 {
        font-size: 1em;
    }

    h6, .td-h6 {
        font-size: 0.85em;
    }

    .td-modal {
        padding: 2.5em 1em;
    }

    .td-close-modal {
        right: 36px;
    }

    .td-list {
        grid-template-columns: 1fr;

        li {
            padding: 0.4em 0;
        }
    }

    .td-container {
        margin: 0 0.5em;
        padding: 0.75em;
    }
}

@media @x-sm-only {
    .td-card {
        padding: 0.75em;
    }

    .td-container {
        max-width: 100%;
        margin: 0;
    }
}

@import './pageHeader.planrv.less';
@import './pageFooter.planrv.less';
@import './tables.planrv.less';
@import './variables.planrv.less';
