/* critical:start */

.td-sidenav {
    background-color: #fff;
    height: calc(100vh - 100px);
    position: sticky;
    top: 50px;
    width: 270px;
    float: left;
    display: flex;
    flex-direction: column;

    &-hidden {
        transition: all ease-in-out 0.35s;
        width: 74px;

        .nav-item {
            padding: 0;
        }

        .item-info {
            width: 0;
            display: none;
        }
    }

    .nav-items {
        padding-top: 2em;
        padding-bottom: 3.5em;
        height: calc(100vh - 100px);
        overflow-y: auto;
        overflow-x: clip;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        .sub-items {
            margin-bottom: 0.75em;
            margin-top: 0.75em;

            .sub-items {
                margin-left: 0.5em;
                border-bottom: solid 1px #e8e8e8;
            }
        }
    }

    .nav-items::-webkit-scrollbar {
        display: none;
    }

    .nav-item {

        &.refresh {
            position: sticky;
            bottom: 0.5em;
            margin-top: auto;
            border-top: 0.08em solid @divider;
            padding-top: 0.25em;
        }

        .main-item {
            & .item-info h5 {
                text-transform: uppercase;
                letter-spacing: 0.1em;
            }
        }

        .item {
            display: grid;
            grid-template-columns: 2fr 8fr;
            justify-content: space-between;
            align-items: center;
            padding: 0.75em 0.5em;
            cursor: pointer;
            text-decoration: none;

            &:hover {
                background-color: @background-orange;

                .item-info h5, .item-icon svg {
                    color: @orange;
                }
            }

            .item-icon {
                justify-self: center;
                padding: 0 0.85em;
                border-radius: 0.7em;

                svg {
                    color: @text-subdued;
                    height: 1.2em;
                    width: 1.2em;
                }
            }

            .item-info h5 {
                margin: 0;
                font-weight: normal;

                @media @lg-down {
                    font-size: 0.9em;
                }
            }
        }

        &.parent {
            border-top: solid 1px #e8e8e8;
        }

        &.active {
            div.item-icon {
                svg {
                    color: @orange-active !important;
                }

                padding: 0 0.65em;

                @media @sm-down {
                    padding: 0 0.35em;
                }
            }

            .item-info h5 {
                color: @orange-active !important;
            }

            .item {
                border-left: 4px solid @orange-active;
                border-top-left-radius: 2px;
                border-bottom-left-radius: 2px;
                color: @orange-active;
                background-color: @background-orange;
            }
        }
    }

    @media @lg-down {
        width: 180px;
        font-size: 0.85em;

        .nav-item .item .item-icon {
            font-size: 0.8em;
        }

        &-hidden {
            width: 74px;
        }
    }

    @media @md-down {
        width: 170px;

        &-hidden {
            width: 74px;
        }
    }

    @media @sm-down {
        width: 120px;
        font-size: 0.65em;

        .nav-item .item .item-icon {
            padding: 0 .6em 0 0.3em;
            font-size: 0.6em;
        }

        &-hidden {
            width: 54px;

            .nav-item .item .item-icon {
                font-size: 1em;
                padding: 0 .85em;
            }
        }
    }

    @media print {
        display: none;
    }

    .toggle-icon {
        display: inline-flex;
        position: absolute;
        top: 0.5em;
        left: 250px;
        border-radius: 50%;
        background-color: @background;
        padding: 0.5em 0.75em;
        box-shadow: @popover-shadow;
        cursor: pointer;

        @media @lg-down {
            left: 165px;
        }

        @media @md-down {
            left: 155px;
        }

        @media @sm-down {
            left: 109px;
        }

        &:hover {
            background-color: @background-orange;
        }

        svg {
            color: @orange;
        }
    }

    .toggle-mob {
        top: 0.5em;
        left: 54px;

        @media @sm-down {
            left: 40px;
        }
    }
}

/* critical:end */

@import '../../Styles/variables.less';
