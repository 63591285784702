.modal-mask {
    z-index: 999;
    display: flex;
    align-items: center;
    position: fixed;
    width: auto;
    height: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-container {
    position: relative;
    width: 85%;
    max-height: 85%;
    padding: 2em 2.5em;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    margin: auto;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 4px;
    scroll-behavior: smooth;

    &.edit-modal {
        padding: 0;
    }

    &.static {
        height: 85%;
    }

    .modal-header {
        width: 100%;
    }

    .modal-header > :first-child {
        margin-top: 0em;
        margin-bottom: 1em;
    }

    .modal-body {
        width: 100%;

        div.loading {
            width: 100%;
            text-align: center;
            font-size: 5em;
        }
    }

    .modal-footer {
        width: 100%;
        margin-top: 1em;

        .actions {
            display: flex;
            justify-content: space-between;
        }
    }

    .x-close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0.5em;
        font-size: 1.25em;

        &:hover {
            color: #0A71A5;
            cursor: pointer;
        }
    }
}

@media @md-down {
    .modal-container {
        padding: 1.5em;
        width: 95%;
        max-height: 95%;

        &.edit-modal div:first-child svg.x-close {
            color: #fff;

            &:hover {
                color: #e1e3e5;
            }
        }

        &.static {
            height: 95%;
        }
    }
}

@media @sm-down {
    .modal-container {
        width: 98%;
        max-height: 98%;

        &.static {
            height: 98%;
        }

        .x-close {
            padding: 0.25em;
        }
    }
}

.edit-modal .modal-container {
    padding: 0 !important;
    position: relative;

    @media only screen and (max-width: 525px) {
        max-width: 100%;
    }

    .modal-body {
        input[type=text] {
            width: 100%;
        }
    }

    .x-close {
        position: absolute;
        right: 0.25em;
        top: 0.25em;
        z-index: 20;
        padding: 0.5em;

        @media @md-down {
            color: #fff;
            top: 0;
            padding: 0.75em;

            &:hover {
                color: @orange;
            }
        }
    }

    .modal-footer {
        display: none;
    }
}

/* Modal */

/* Transition name="modal" */

.modal-enter-active,
.modal-leave-active {
    transition: opacity 0.5s ease;
}

.modal-enter,
.modal-leave-to {
    opacity: 0;
}

/* Modal End */

@import "../../Styles/variables.less";