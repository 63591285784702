.admin-tasks-link {
  position: relative;
}
.admin-tasks-link a {
  height: 28px;
}
.admin-tasks-link .td-circle-badge {
  font-size: 0.7rem;
  font-weight: 700;
  width: 25px;
  height: 25px;
  position: absolute;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  top: -0.4em;
  right: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
  background-color: #f87d20;
}
.admin-tasks-link .task-manager svg {
  color: #454545;
}
.planrv .admin-tasks-link .td-circle-badge {
  background-color: #eb5643;
}
/* colors */
/* Fonts */
/* Display breakpoints */
/* most of the modern smartphones have dimesions >= 375px*/
/* older smartphones like iPhone 4, iPhone 5, etc.*/
/* Shadows */
/* Shapes */
