.admin-tasks-link {
    position: relative;

    a {
        height: 28px;
    }

    .td-circle-badge {
        font-size: 0.7rem;
        font-weight: 700;
        width: 25px;
        height: 25px;
        position: absolute;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        top: -0.4em;
        right: 0;
        animation: fadeIn 0.5s ease-in-out forwards;
        background-color: @orange;
    }

    .task-manager {
        svg { color: @dark-grey; }
    }
}

.planrv {
    .admin-tasks-link .td-circle-badge {
        background-color: @planrv-primary;
    }
}
@import '../../Styles/variables.less';