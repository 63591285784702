/* critical:start */
.td-sidenav {
  background-color: #fff;
  height: calc(100vh - 100px);
  position: sticky;
  top: 50px;
  width: 270px;
  float: left;
  display: flex;
  flex-direction: column;
}
.td-sidenav-hidden {
  transition: all ease-in-out 0.35s;
  width: 74px;
}
.td-sidenav-hidden .nav-item {
  padding: 0;
}
.td-sidenav-hidden .item-info {
  width: 0;
  display: none;
}
.td-sidenav .nav-items {
  padding-top: 2em;
  padding-bottom: 3.5em;
  height: calc(100vh - 100px);
  overflow-y: auto;
  overflow-x: clip;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.td-sidenav .nav-items .sub-items {
  margin-bottom: 0.75em;
  margin-top: 0.75em;
}
.td-sidenav .nav-items .sub-items .sub-items {
  margin-left: 0.5em;
  border-bottom: solid 1px #e8e8e8;
}
.td-sidenav .nav-items::-webkit-scrollbar {
  display: none;
}
.td-sidenav .nav-item.refresh {
  position: sticky;
  bottom: 0.5em;
  margin-top: auto;
  border-top: 0.08em solid #E1E3E5;
  padding-top: 0.25em;
}
.td-sidenav .nav-item .main-item .item-info h5 {
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.td-sidenav .nav-item .item {
  display: grid;
  grid-template-columns: 2fr 8fr;
  justify-content: space-between;
  align-items: center;
  padding: 0.75em 0.5em;
  cursor: pointer;
  text-decoration: none;
}
.td-sidenav .nav-item .item:hover {
  background-color: #FFFAF7;
}
.td-sidenav .nav-item .item:hover .item-info h5,
.td-sidenav .nav-item .item:hover .item-icon svg {
  color: #f87d20;
}
.td-sidenav .nav-item .item .item-icon {
  justify-self: center;
  padding: 0 0.85em;
  border-radius: 0.7em;
}
.td-sidenav .nav-item .item .item-icon svg {
  color: #6D7175;
  height: 1.2em;
  width: 1.2em;
}
.td-sidenav .nav-item .item .item-info h5 {
  margin: 0;
  font-weight: normal;
}
@media only screen and (max-width: 1399px) {
  .td-sidenav .nav-item .item .item-info h5 {
    font-size: 0.9em;
  }
}
.td-sidenav .nav-item.parent {
  border-top: solid 1px #e8e8e8;
}
.td-sidenav .nav-item.active div.item-icon {
  padding: 0 0.65em;
}
.td-sidenav .nav-item.active div.item-icon svg {
  color: #E5610C !important;
}
@media only screen and (max-width: 525px) {
  .td-sidenav .nav-item.active div.item-icon {
    padding: 0 0.35em;
  }
}
.td-sidenav .nav-item.active .item-info h5 {
  color: #E5610C !important;
}
.td-sidenav .nav-item.active .item {
  border-left: 4px solid #E5610C;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  color: #E5610C;
  background-color: #FFFAF7;
}
@media only screen and (max-width: 1399px) {
  .td-sidenav {
    width: 180px;
    font-size: 0.85em;
  }
  .td-sidenav .nav-item .item .item-icon {
    font-size: 0.8em;
  }
  .td-sidenav-hidden {
    width: 74px;
  }
}
@media only screen and (max-width: 959px) {
  .td-sidenav {
    width: 170px;
  }
  .td-sidenav-hidden {
    width: 74px;
  }
}
@media only screen and (max-width: 525px) {
  .td-sidenav {
    width: 120px;
    font-size: 0.65em;
  }
  .td-sidenav .nav-item .item .item-icon {
    padding: 0 0.6em 0 0.3em;
    font-size: 0.6em;
  }
  .td-sidenav-hidden {
    width: 54px;
  }
  .td-sidenav-hidden .nav-item .item .item-icon {
    font-size: 1em;
    padding: 0 0.85em;
  }
}
@media print {
  .td-sidenav {
    display: none;
  }
}
.td-sidenav .toggle-icon {
  display: inline-flex;
  position: absolute;
  top: 0.5em;
  left: 250px;
  border-radius: 50%;
  background-color: #F6F6F7;
  padding: 0.5em 0.75em;
  box-shadow: 0 3px 6px -3px rgba(23, 24, 24, 0.08), 0 8px 20px -4px rgba(23, 24, 24, 0.12);
  cursor: pointer;
}
@media only screen and (max-width: 1399px) {
  .td-sidenav .toggle-icon {
    left: 165px;
  }
}
@media only screen and (max-width: 959px) {
  .td-sidenav .toggle-icon {
    left: 155px;
  }
}
@media only screen and (max-width: 525px) {
  .td-sidenav .toggle-icon {
    left: 109px;
  }
}
.td-sidenav .toggle-icon:hover {
  background-color: #FFFAF7;
}
.td-sidenav .toggle-icon svg {
  color: #f87d20;
}
.td-sidenav .toggle-mob {
  top: 0.5em;
  left: 54px;
}
@media only screen and (max-width: 525px) {
  .td-sidenav .toggle-mob {
    left: 40px;
  }
}
/* critical:end */
/* colors */
/* Fonts */
/* Display breakpoints */
/* most of the modern smartphones have dimesions >= 375px*/
/* older smartphones like iPhone 4, iPhone 5, etc.*/
/* Shadows */
/* Shapes */
