.side-menu .layoutdropdown-enter-active {
  animation: openlayoutdropdown 0.3s ease-in-out;
}
.side-menu .layoutdropdown-leave-active {
  animation: closelayoutdropdown 0.3s ease-in-out;
}
@keyframes openlayoutdropdown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes closelayoutdropdown {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}
.side-menu div.menu-list {
  position: absolute;
  z-index: 1000;
  right: 0;
  top: 50px;
  width: 300px;
  max-width: 95%;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  padding: 0.25em;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.side-menu div.menu-list > a.item {
  display: block;
  padding: 0.5em;
  border-bottom: 1px solid #F5F5F5;
  color: #454545;
  text-decoration: none;
  font-weight: 700;
}
.side-menu div.menu-list > a.item:hover {
  color: #f87d20;
}
.side-menu div.menu-list > a.item:hover span.title {
  color: #454545;
}
.side-menu div.menu-list > a.item:last-child {
  border-bottom: none;
}
.side-menu div.menu-list > a.item > span.title {
  display: block;
  font-size: 0.8em;
  font-weight: normal;
  margin-top: 0.25em;
  color: #6D7175;
}
.planrv .side-menu div.menu-list > a.item:hover {
  color: #ec442e;
}
/* colors */
/* Fonts */
/* Display breakpoints */
/* most of the modern smartphones have dimesions >= 375px*/
/* older smartphones like iPhone 4, iPhone 5, etc.*/
/* Shadows */
/* Shapes */
