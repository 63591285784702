/* critical:start */
.recaptcha {
  width: 304px;
  height: 78px;
  background: #f9f9f9;
  color: #000;
  border: 1px solid #d3d3d3;
  display: flex;
  justify-content: center;
  align-items: center;
}
.recaptcha.clear {
  background: none;
  border: none;
}
.recaptcha svg {
  width: 30px;
  height: 30px;
}
/* critical:end */
