@import './variables.planrv.less';

#footer {
    .footer-wrapper {
        padding: 0 1.5em 1em 1.5em;
        border-top: 1px solid @light-grey;

        .footer-links {
            width: 100%;
            display: grid;
            grid-template-columns: auto auto auto auto;
            padding-bottom: 1em;

            .links-column {

                a {
                    color: @dark-grey;
                    display: block;
                    padding: 0.45em 0;
                    text-decoration: none;

                    &:hover {
                        color: @planrv-primary-active;
                    }

                    &.brand {
                        width: 30px;
                        height: 30px;
                        display: inline-block;
                        margin-right: 0.75em;

                        svg {
                            width: 30px;
                            height: 30px;
                        }
                    }

                    span.td-tex-mice {
                        font-size: 0.75em;
                    }
                }

                a:first-of-type {
                    font-weight: 700;
                    padding: 1em 0 0.75em 0;
                    line-height: 0.9;
                    text-decoration: none;
                    margin-bottom: 0.75em;
                    font-size: 1em;
                    letter-spacing: 0.02em;

                    &:hover {
                        color: @planrv-primary-active;
                    }
                }
            }
        }

        .footer-paragraph {
            text-align: center;
            margin: 1em auto;
            max-width: 800px;
            font-size: 0.8em;
        }

        @media @md-down {
            padding: 0 1em 1em 1em;

            .footer-links {
                grid-template-columns: auto auto;
                grid-row-gap: 1em;

                .links-column a:first-of-type {
                    margin-bottom: 0.25em;
                }
            }
        }

        @media @sm-down {
            .footer-links {
                grid-template-columns: auto;
            }
        }
    }
}
