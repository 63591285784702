.modal-mask {
  z-index: 999;
  display: flex;
  align-items: center;
  position: fixed;
  width: auto;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-container {
  position: relative;
  width: 85%;
  max-height: 85%;
  padding: 2em 2.5em;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  margin: auto;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 4px;
  scroll-behavior: smooth;
}
.modal-container.edit-modal {
  padding: 0;
}
.modal-container.static {
  height: 85%;
}
.modal-container .modal-header {
  width: 100%;
}
.modal-container .modal-header > :first-child {
  margin-top: 0em;
  margin-bottom: 1em;
}
.modal-container .modal-body {
  width: 100%;
}
.modal-container .modal-body div.loading {
  width: 100%;
  text-align: center;
  font-size: 5em;
}
.modal-container .modal-footer {
  width: 100%;
  margin-top: 1em;
}
.modal-container .modal-footer .actions {
  display: flex;
  justify-content: space-between;
}
.modal-container .x-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5em;
  font-size: 1.25em;
}
.modal-container .x-close:hover {
  color: #0A71A5;
  cursor: pointer;
}
@media only screen and (max-width: 959px) {
  .modal-container {
    padding: 1.5em;
    width: 95%;
    max-height: 95%;
  }
  .modal-container.edit-modal div:first-child svg.x-close {
    color: #fff;
  }
  .modal-container.edit-modal div:first-child svg.x-close:hover {
    color: #e1e3e5;
  }
  .modal-container.static {
    height: 95%;
  }
}
@media only screen and (max-width: 525px) {
  .modal-container {
    width: 98%;
    max-height: 98%;
  }
  .modal-container.static {
    height: 98%;
  }
  .modal-container .x-close {
    padding: 0.25em;
  }
}
.edit-modal .modal-container {
  padding: 0 !important;
  position: relative;
}
@media only screen and (max-width: 525px) {
  .edit-modal .modal-container {
    max-width: 100%;
  }
}
.edit-modal .modal-container .modal-body input[type=text] {
  width: 100%;
}
.edit-modal .modal-container .x-close {
  position: absolute;
  right: 0.25em;
  top: 0.25em;
  z-index: 20;
  padding: 0.5em;
}
@media only screen and (max-width: 959px) {
  .edit-modal .modal-container .x-close {
    color: #fff;
    top: 0;
    padding: 0.75em;
  }
  .edit-modal .modal-container .x-close:hover {
    color: #f87d20;
  }
}
.edit-modal .modal-container .modal-footer {
  display: none;
}
/* Modal */
/* Transition name="modal" */
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.5s ease;
}
.modal-enter,
.modal-leave-to {
  opacity: 0;
}
/* Modal End */
/* colors */
/* Fonts */
/* Display breakpoints */
/* most of the modern smartphones have dimesions >= 375px*/
/* older smartphones like iPhone 4, iPhone 5, etc.*/
/* Shadows */
/* Shapes */
