/* critical:start */

#header {
    height: 50px;

    #navbar {
        width: 100%;
        height: 50px;
        background-color: rgba(255,255,255,.85);
        border-bottom: 1px solid @light-grey;
        display: grid;
        grid-template-columns: min-content auto max-content max-content max-content max-content max-content;
        grid-column-gap: 0;
        padding: 0 1em;
        align-items: center;
        top: 0;
        position: fixed;
        z-index: 100;

        .td-logo {
            margin-right: 0.5em;
        }

        .nav-items {
            display: flex;
            flex-wrap: nowrap;
            height: 100%;
            align-items: center;

            a {
                font-weight: bold;
                color: @dark-grey;
                padding: 0 0.5em;
                height: 100%;
                display: flex;
                align-items: center;
                text-decoration: none;
                letter-spacing: 0.02em;

                &:not(:last-of-type) {
                    border-right: 1px solid @light-grey;
                }

                &:hover {
                    color: @planrv-primary-active;
                }
            }
        }

        .side-menu, .admin-tasks-link {
            display: flex;
            padding-left: 0.75em;
            justify-self: end;

            .nav-icon {
                height: 30px;
                width: 30px;
                cursor: pointer;
            }
        }

        .admin-tasks-link {
            padding-right: 0.75em;
            padding-left: 1.25em;
            width: 66px;
            height: 28px;
        }

        .admin-input {
            position: relative;

            .input-icon {
                width: 1em;
                height: 1em;
                position: absolute;
                top: 0.5em;
                right: 0.5em;
            }

            .admin-search {
                width: 200px;
                font-size: 0.8em;
            }

            @media @lg-down {
                text-align: right;
                justify-self: end;

                .admin-search {
                    display: none;
                }

                .input-icon {
                    width: 26px;
                    height: 26px;
                    position: relative;
                    top: 0;
                    right: 0;
                    margin-left: 0.25em;
                    display: flex;
                }
            }
        }

        .login-menu {
            justify-content: end;
            font-size: 0.9em;
        }

        .account-settings {
            height: 50px;
            display: flex;
            align-items: center;
            color: @dark-grey;
            text-decoration: none;
            column-gap: 0.75em;
            font-size: 0.8em;
            margin: 0 0.5em;
            max-width: 185px;
            min-width: 0;

            img {
                animation: fadeIn 0.5s ease-in-out forwards;
            }

            span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        @media @md-down {
            grid-template-columns: min-content auto max-content max-content max-content;
        }

        @media only screen and (max-width: 330px) {
            .account-settings {
                display: none;
            }
        }
    }
}

/* critical:end */

@import './variables.planrv.less';
