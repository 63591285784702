.pager-pagination {
  display: grid;
  grid-column-gap: 0.5em;
  align-items: center;
  grid-template-columns: max-content 1fr;
  padding: 0 1em;
  box-shadow: 0 0.125rem 0.25rem rgba(31, 33, 36, 0.1), 0 0.0625rem 0.375rem rgba(31, 33, 36, 0.05);
  background-color: #fff;
}
.pager-pagination.down {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.pager-pagination .td-pagination-btns {
  justify-self: end;
}
