.side-menu {

    .layoutdropdown-enter-active {
        animation: openlayoutdropdown 0.3s ease-in-out;
    }

    .layoutdropdown-leave-active {
        animation: closelayoutdropdown 0.3s ease-in-out;
    }

    @keyframes openlayoutdropdown {
        from {
            opacity: 0;
            transform: translateY(-10px);
        }

        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes closelayoutdropdown {
        from {
            opacity: 1;
            transform: translateY(0);
        }

        to {
            opacity: 0;
            transform: translateY(-10px);
        }
    }

    div.menu-list {
        position: absolute;
        z-index: 1000;
        right: 0;
        top: 50px;
        width: 300px;
        max-width: 95%;
        max-height: calc(100vh - 50px);
        overflow-y: auto;
        box-shadow: @dropdown-shadow;
        background-color: #fff;
        padding: 0.25em;
        border-bottom-left-radius: @border-radius;
        border-bottom-right-radius: @border-radius;

        > a.item {
            display: block;
            padding: 0.5em;
            border-bottom: 1px solid @light-grey;
            color: @dark-grey;
            text-decoration: none;
            font-weight: 700;

            &:hover {
                color: @orange;

                & span.title {
                    color: @dark-grey;
                }
            }

            &:last-child {
                border-bottom: none;
            }

            > span.title {
                display: block;
                font-size: 0.8em;
                font-weight: normal;
                margin-top: 0.25em;
                color: @text-subdued;
            }
        }
    }
}

.planrv {
    .side-menu div.menu-list > a.item:hover {
        color: @planrv-primary-active;
    }
}

@import '../../Styles/variables.less';