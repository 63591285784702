/* Table */

.td-table-container {
    overflow-x: auto;
    box-shadow: @base-shadow;
    background-color: #fff;
    border-radius: 4px;
    margin-top: 1.5em;

    table, .td-table {
        table-layout: auto;
        box-shadow: none;
        width: 100%;

        td {
            padding: 0.5em 1em;
            vertical-align: middle;
            text-align: left;
            border-bottom: 1px solid @divider;

            &.break-word {
                word-break: break-all;
            }

            p {
                margin: 0;
            }
        }

        tbody, thead {
            tr.header-actions {
                border-top-left-radius: @border-radius;
                border-top-right-radius: @border-radius;
                background-color: #cdcdce1a;

                th {
                    padding: 0.5em 1em;
                    margin: auto 0;
                    border-bottom: none;

                    .action-btns {
                        text-align: right;
                        text-align: -webkit-right;
                        justify-self: end;
                    }
                }
            }

            tr.table-header {
                background-color: @planrv-secondary-dark;
                color: white;

                th {
                    padding: 0.5em 1em;
                    text-align: left;
                }
            }

            tr.table-row {
                td {
                    vertical-align: baseline;
                    min-width: 150px;

                    &.short {
                        max-width: 200px;
                    }

                    & p.table-subheading {
                        color: @dark-blue;
                        margin-top: 0.25em;
                        font-weight: bold;
                    }

                    &.nowrap {
                        white-space: nowrap;
                    }
                }

                .action-btns {

                    button {
                        font-size: 0.9em;
                        min-width: 100px;
                        display: block;

                        svg {
                            padding-right: 0.5em;
                        }
                    }

                    &.align-right {
                        text-align: right;
                        text-align: -webkit-right;
                    }

                    &.align-left {
                        text-align: left;
                        text-align: -webkit-left;
                    }
                }
            }
        }

        tfoot {

            tr {
                background-color: #cdcdce1a;
            }

            tr.pagination-row {

                .total {
                    p {
                        margin: 0;
                        font-size: 0.9em;
                        font-style: oblique;
                    }
                }

                .pages {
                    text-align: right;
                }
            }
        }
    }
}

/* Table End */

/* Edit Container with steps and without */

.td-edit-container {
    height: 85vh;
    min-height: 100%;
    display: grid;
    grid-template-columns: 30% 70%;

    @media @x-xl-only {
        height: 60vh;
    }

    &.one-step {
        height: 100%;

        .pagination {
            padding: 1.5em;

            h3 {
                margin-bottom: 0;
                font-size: 1.5em;
            }

            .step-description .step.active {
                max-height: 300px;
            }
        }

        @media @lg-up {
            .pagination {
                padding: 10em 2.5em;
            }

            .edit-pages {
                padding: 2.5em 2.5em 0 2.5em;
            }
        }
    }

    .pagination {
        color: #fff;
        background-color: @planrv-secondary-dark;
        padding: 3em 2.5em;

        .form-name {
            h3 {
                margin-top: 0;
                color: #fff;
            }
        }

        .step-description {
            .step {
                display: none;

                h5 {
                    text-transform: uppercase;
                    font-size: 1em;
                    margin-bottom: 0.5em;
                    margin-top: 0.5em;
                    color: #fff;
                }

                &.active {
                    display: block;
                    max-height: 140px;
                    overflow: hidden;

                    p {
                        margin-top: 0.5em;
                    }

                    @media @md-down {
                        display: none;
                    }
                }
            }
        }

        .form-steps {
            border-left: 2.5px dotted @planrv-primary;
            margin-left: 20px;
            position: relative;

            .form-step {
                margin: 2.5em;
                cursor: pointer;
                display: flex;
                align-items: center;
                font-size: 1.1em;

                &.active {
                    color: @planrv-primary-active;
                    font-weight: bold;

                    .step-count {
                        background-color: @planrv-primary;
                        color: #fff;
                    }
                }

                .step-count {
                    background-color: #fff;
                    color: @planrv-primary;
                    position: absolute;
                    z-index: 10000;
                    left: -24px;
                    border-radius: 50%;
                    padding: 0.4em 0.8em;
                    font-weight: bold;
                }
            }
        }
    }

    .edit-pages {
        padding: 2.5em 2.5em 0 2.5em;
        display: grid;
        grid-template-rows: calc(100% - 80px) 80px;

        .form-page {
            display: none;

            .wide-form {
                grid-template-rows: auto;
            }

            &.active {
                display: grid;
                grid-template-columns: 48% 48%;
                grid-column-gap: 1.5em;
                padding-bottom: 2em;

                @media @sm-down {
                    grid-template-columns: 100%;
                }

                &.wide-form {
                    grid-template-columns: 100%;
                    align-content: start;
                }
            }

            label {
                margin-top: 0.5em;
                font-size: 1em;
            }
        }

        .actions {
            align-self: end;
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding: 1.5em 0 2.5em 0;
            position: sticky;
            bottom: 0;
            background-color: #fff;
            z-index: 30;
            width: 100%;
            width: -webkit-fill-available;
            width: -moz-available;

            button {
                padding: 0.5em 1.25em;
            }

            .save-btn {
                justify-self: end;
                align-self: end;
            }
        }
    }
}

@media @md-down {

    .td-edit-container {
        grid-template-columns: 100%;
        height: auto;
        grid-template-rows: max-content auto;

        .pagination {
            padding: 1.75em 0.5em;
            height: fit-content;

            .form-name {
                h3 {
                    margin-top: 0.25em;
                    margin-bottom: 0.75em;
                    text-align: center;
                    font-size: 1.25em;
                }
            }

            .form-steps {
                display: grid;
                grid-template-columns: auto auto auto;
                border-left: none;
                margin-left: 0;
                column-gap: 3%;
                justify-content: center;

                .form-step {
                    max-width: 100%;
                    margin: 0.5em;
                    display: inline-block;
                    font-size: 0.9em;

                    .step-count {
                        position: relative;
                        left: -6px;
                    }
                }
            }
        }

        &.one-step .pagination {
            margin: -1.5em -1.5em 0 -1.5em;
        }

        .edit-pages {
            height: 100%;
            padding: 0;
            grid-template-rows: calc(100% - 120px) 120px;

            .form-page.active {
                padding: 1em;

                .inputs-column {
                    padding: 0 0.1em;
                }
            }

            .actions {
                box-shadow: none;
                padding: 1.5em;
                position: relative !important;
                grid-template-columns: max-content auto;

                button {
                    padding: 0.5em 0.75em;
                    min-height: 2em;
                }
            }
        }
    }

    .td-table-container table.td-table {
        .header-actions {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 1em;
            border-bottom: 1px solid #E1E3E5;

            & th:last-of-type {
                justify-self: end;
            }
        }

        .table-row {
            td {
                max-width: 100%;
                padding: 0.25em 0.5em;
                border: none;
                word-break: break-word;
            }

            td.action-btns {
                display: flex;
                align-items: baseline;
                justify-content: space-between;
            }
        }

        tfoot tr.pagination-row {
            display: grid;
            grid-template-columns: auto auto;
            justify-content: space-between;
            align-content: center;
            align-items: center;

            td {
                padding: 0.5em;
                border-bottom: none;
            }

            td:first-of-type {
                white-space: pre-wrap;
            }

            .total {
                font-size: 0.9em;
            }
        }
    }
}


@media @x-sm-only {

    .td-edit-container {
        height: 400px;
    }

    .td-table-container table.td-table {
        tfoot {
            tr.pagination-row {
                grid-template-columns: min-content auto;
            }
        }

        tbody .header-actions {
            th {
                padding: 0.5em 0.25em;
            }
        }
    }
}


/* critical:start */

@media @md-down {
    .td-table-container table.td-table {
        width: 100%;

        .table-header {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        .table-row {
            display: grid;
            grid-template-columns: 1fr 1fr;
            border-bottom: 1px solid @divider;
            padding: 0.5em 0.25em;
            font-size: 0.9em;
        }
    }
}

@media @sm-down {

    .td-table-container table.td-table {

        tbody tr.header-actions th label {
            display: block;
        }

        .table-row {
            grid-template-columns: 1fr;
            padding: 0.5em 0;

            button {
                padding: 0.5em;
            }
        }
    }
}

/* critical:end */

@import './variables.planrv.less';
